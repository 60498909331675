import styles from '../../styles/HeroChecks.module.css';
import { TextDTO } from '../../lib/types';
import CHECK_2 from '../../public/images/check-2.svg';

const HeroChecks = ({ checks }: { checks: TextDTO[] }) => {
  return (
    <div className={styles.heroChecks}>
      {checks.map((check) => (
        <div key={check.text} className={styles.row}>
          <img src={CHECK_2.src} alt="" className={styles.icon} />
          <p className={styles.text}>{check.text}</p>
        </div>
      ))}
    </div>
  );
};

export default HeroChecks;
