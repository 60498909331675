import styles from '../../styles/Hero.module.css';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import Link from 'next/link';
import { useDispatch } from 'react-redux';
import { clickedCTA } from '../../store/actions/events';
import { ctaClickSources } from '../../lib/constants';
import { useRouter } from 'next/router';
import { TextDTO } from '../../lib/types';
import HeroChecks from '../HeroChecks/index';
import HeroRatings from '../HeroRatings/index';

const Hero = (props: {
  title: string;
  subtitle: string;
  ctaText?: string;
  ctaLink: string;
  backgroundImage: string;
  mobileBackgroundImage?: string;
  checks?: TextDTO[];
  ratingsText: string;
}) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(window.innerWidth);
    const handleResize = debounce(() => {
      setWidth(window.innerWidth);
    }, 250);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function getBackgroundImage() {
    if (width == 0) {
      return '';
    }
    if (width < 768) {
      return props.mobileBackgroundImage || props.backgroundImage;
    }
    return props.backgroundImage;
  }

  if (
    !props.title &&
    !props.subtitle &&
    !props.ctaText &&
    !props.ctaLink &&
    !props.backgroundImage &&
    !props.mobileBackgroundImage
  )
    return <></>;

  return (
    <div
      className={`jumbotron mb-5 mb-md-0 ${styles.hero}`}
      style={{ backgroundImage: `url(${getBackgroundImage()})` }}
    >
      <Container className={`${styles.heroContainer} h-100 px-md-5`}>
        <Row
          className={`h-100 align-items-md-center align-items-end ${styles.content}`}
        >
          <Col md="7" xl="7" xxl="6">
            <h2 className={`${styles.title} display-2`}> {props.title} </h2>
            {props.checks && Boolean(props.checks.length) && (
              <HeroChecks checks={props.checks} />
            )}

            {props.ratingsText && (
              <HeroRatings ratingsText={props.ratingsText} />
            )}

            {props.ctaText && props.ctaLink && (
              <Link href={props.ctaLink}>
                <Button
                  size="lg"
                  className={[
                    styles.button,
                    'd-flex align-items-center justify-content-center',
                  ].join(' ')}
                  onClick={() =>
                    dispatch(
                      clickedCTA({
                        name: ctaClickSources.hero.name,
                        url: router.asPath,
                      })
                    )
                  }
                >
                  <div className="me-3">{props.ctaText}</div>
                </Button>
              </Link>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Hero;
